import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Box } from "theme-ui";
import Layout from "../components/layout";
import Link from "../components/Link";
import routes from "../routes";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
      <Box sx={{
        textAlign: "center"
      }} mdxType="Box">
        <h1>{`Hello, World! I'm Dawson.`}</h1>
        <Link to={routes.PROJECTS} mdxType="Link">Go to Projects</Link>
      </Box>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      